import React from 'react';
import '../../Cursor.scss';
import '../css/newstyle.scss'
import Draggable from 'react-draggable';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'




import image from '../../img/home-image.jpg';


import pullDown from '../../icons/svg/pulldown.svg';
import facebook from '../../img/facebook.svg';
import instagramm from '../../img/instagramm.svg';

import shape1 from '../../img/bg/Artboard 6.svg';
import shape2 from '../../img/bg/Artboard 9.svg';
import shape3 from '../../img/bg/Artboard 8.svg';
import shape4 from '../../img/bg/Artboard 10.svg';
import shape5 from '../../img/bg/Artboard 11.svg';
import shape6 from '../../img/bg/Artboard 7.svg';
import fig from '../../img/bg/figure.svg';
import logo from '../../img/logo2.svg';


import solutionTop1 from '../../img/bg/solutionTop1.svg';
import solutionTop2 from '../../img/bg/solutionTop2.svg';
import solutionTop3 from '../../img/bg/solutionTop3.svg';
import solutionFig from '../../img/bg/solutionFig.svg';
import solutionPuzzle from '../../img/puzzle.svg';
import solutionSettings from '../../img/settings.svg';
import solutionBulb from '../../img/lightbulb.svg';
import solutionBot1 from '../../img/bg/solutionBot1.svg';
import solutionBot2 from '../../img/bg/solutionBot2.svg';
import solutionBot3 from '../../img/bg/solutionBot3.svg';
import solutionBot4 from '../../img/bg/solutionBot4.svg';


import fig3 from '../../img/bg/fig3.svg';
import study from '../../img/study.svg';
import idea from '../../img/idea.svg';
import webDesign from '../../img/web-design.svg';



import contact1 from '../../img/bg/contact1.svg';
import contact2 from '../../img/bg/contact2.svg';
import contact3 from '../../img/bg/contact3.svg';
import contact4 from '../../img/bg/contact4.svg';
import contactFig from '../../img/bg/contactFig.svg';
import {db, googleAnalitcs} from "../firebase";
import PopupRenderer from "../popups/PopupRenderer";
import ScrollToTop from "../popups/scrollToTop";
import Works from "../homepage-components/works";
import Competitions from "../homepage-components/competitions";

const DEBUG = false;




class Page1 extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            firstN: '',
            lastN: '',
            email: '',
            message: '',
            popupShow: false,
            popupMessage: '',
            scrollToTopShow: false,
            loadingScreen: true,
        };

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    state = {
        navShow: false,
        controlledPosition: {
            x: 0, y: 0
        },

    };

    handleSubmit(e){
        e.preventDefault();
        e.stopPropagation();
        //console.log('submitted');
        db.collection('email').doc(String(new Date().getTime())).set({
            date: new Date(),
            firstN: this.state.firstN,
            lastN: this.state.lastN,
            email: this.state.email,
            message: this.state.message,
        }).then(()=>{
            //console.log('success');
            this.setState({
                popupMessage: 'SEND_SUCCESS',
                popupShow: true,
                firstN: '',
                lastN: '',
                email: '',
                message: '',
            });

        }).catch((err)=>{
            //console.log(err);
            this.setState({
                popupMessage: 'SEND_ERROR',
                popupShow: true
            });
        });

        //todo contact message
    };
    sendSuccess = async () => {
        //console.log('show popup');
        await this.setState({
            popupMessage: 'SEND_SUCCESS',
            popupShow: true
        });

    };
    sendError = async (err) => {

    };
    closePopup = async () => {
        await this.setState({
            popupMessage: '',
            popupShow: false
        });
    };
    handleOnChange(e){
        this.setState({[e.target.id]: e.target.value})
    };
    logsOnMount(){
        let APIkey = db.collection('keys').doc('LpjAdxYGcOdAFGRV6BkC');
        APIkey.get()
            .then(doc => {
                let url;
                url = `https://api.ipdata.co/?api-key=${doc.data().key}`;
                fetch(url)
                    .then(res => res.json())
                    .then(jsonData => {
                        jsonData['date'] = new Date();
                        db.collection('logs').add(jsonData);
                    })
                    .catch(err => {
                        //console.log(err)
                    });
            });
    };
    navDragStart = async (e, ui) => {
        let navbarS = document.getElementById('navbar1').style;
        let navbarMenuS = document.getElementById('menu').style;

        //console.log('start');
        navbarS.transition = '';
        navbarMenuS.transition = '';


        document.getElementById('pulldown-text').style.display = 'none';
        document.getElementById('pulldown').style.display = 'none';


    };
    navDrag = async (e, ui) => {

        let navbarMenuS = document.getElementById('menu').style;
        // noinspection JSValidateTypes
        navbarMenuS.opacity = ((await ui.lastY)/(this.state.botPos * 1.2));


        //console.log(e, 'e');
        //console.log(await ui.lastY);
        //const {y} = ui;
        let navbarS = document.getElementById('navbar1').style;
        // this.setState({controlledPosition: {
        //         x: 0, y: y
        //     },});
        if (this.state.navShow){
            //console.log('opened');
            if (await ui.lastY < (this.state.botPos/1.5)){
                //console.log('close');
                navbarS.transition = 'transform 1s';
                navbarMenuS.transition = 'opacity 1s';
                this.setState({
                    navShow: false,
                    controlledPosition: {
                        x: 0, y: 0
                    },
                });
            }
        } else {
            //console.log('closed');
            if (await ui.lastY >= (this.state.botPos/6)){
                //console.log('open');
                navbarS.transition = 'transform 1s';
                this.setState({
                    navShow: true,
                    controlledPosition: {
                        x: 0, y: this.state.botPos
                    },
                });
            } else {
                this.setState({
                    navShow: false,
                    controlledPosition: {
                        x: 0, y: 0
                    },
                });
            }
        }
        //console.log(this.state);
    };
    navDragEnd = async (e, ui) => {


        //console.log('nav end');

        let navbarS = document.getElementById('navbar1').style;
        let navbarMenuS = document.getElementById('menu').style;
        //console.log(ui.lastY, 'end');
        navbarS.transition = 'transform 1s';
        navbarMenuS.transition = 'opacity 1s';


        if (await this.state.navShow){
           navbarMenuS.opacity = '1';
        } else {
            //console.log('opacity');
            navbarMenuS.opacity = '0';
        }

        // if (await ui.lastY < 300){
        //     console.log('back');
        //     navbarS.transform = 'translate(0px, 0px)';
        // }





        };
    navLink = async () => {
        this.setState({
            navShow: false,
            controlledPosition: {
                x: 0, y: 0
            },
        });
        let navbarMenuS = document.getElementById('menu').style;
        navbarMenuS.opacity = '0';



    };
    updateDimensions = () => {
        //console.log('resize');
        //console.log(window.outerHeight);
        let botBound = window.innerWidth * 1.130434782;
        let topBound = window.innerWidth * -0.086956521;
        let botPos = window.innerWidth * 1.04347826086;
        //console.log(topBound);
        //console.log(botBound);
        this.setState({topBound: topBound, botBound: botBound, botPos: botPos});
        if (this.state.navShow) {
            document.getElementById('navbar1').style.transition = 'transform 0s';
            this.setState({
                controlledPosition: {
                    x: 0, y: this.state.botPos
                },
            });
        }

        //console.log(window.innerWidth);
        if(window.innerWidth >= 615){
            this.setState({
                controlledPosition: {x: 0, y:0},
            });
            let navbarMenuS = document.getElementById('menu').style;
            navbarMenuS.transition = 'opacity 1s';


        }

    };

    componentWillMount() {
        let botBound = window.innerWidth * 1.130434782;
        let topBound = window.innerWidth * -0.086956521;
        let botPos = window.innerWidth * 1.04347826086;
        //console.log(topBound);
        //console.log(botBound);
        //console.log('botPos:', botPos);

        this.setState({topBound: topBound, botBound: botBound, botPos: botPos});



        //Get texts from Firebase Database
        //with the following document names

        let text_ids = [
            'sol1',
            'sol2',
            'sol3',
            'about1',
            'about2',
            'about3',
            ];
        text_ids.forEach((v, i)=>{
            db.collection('texts').doc(v).get().then(doc => {
               this.setState({[v]:doc.data().text});
               //console.log(this.state)
            });
        });




    }
    componentDidMount() {




        /*console.log(window.innerWidth); //575
        this.setState({width: window.innerWidth});

        let botBound = window.innerWidth * 1.130434782;
        let topBound = window.innerWidth * 0.086956521;
        console.log(topBound)*/

        window.addEventListener('resize', this.updateDimensions);
        //console.log('trigger');


        //let solTop = document.getElementById('solutionTop');
        //console.log(solTop.offsetTop, 'sol');
        //console.log(window.innerHeight, 'inner');
        //console.log(document.body.scrollHeight, 'body');

        googleAnalitcs.logEvent('visit');


        //todo reactivate on deploy
        if (!DEBUG) {
            this.logsOnMount();
            window.addEventListener('load', ()=>{
                //console.log('loaded');
                this.setState({loadingScreen: false});
            });
        } else {
            this.setState({loadingScreen: false})
        }
        window.onscroll = () => {
            //console.log(window.pageYOffset);
            let scrollBeginPos = window.pageYOffset;
            setTimeout(() => {
                //console.log('curr ' + window.pageYOffset);
                //console.log('firs' + scrollBeginPos);
                if ((scrollBeginPos - window.pageYOffset) < 0 && this.state.scrollToTopShow) {
                    //console.log('hide by other');
                    this.setState({scrollToTopShow: false});

                }
                if ((scrollBeginPos - window.pageYOffset) > 700 && !this.state.scrollToTopShow) {
                    //console.log('diff ' + (scrollBeginPos - window.pageYOffset));
                    //console.log('dispaly');
                    this.setState({scrollToTopShow: true});

                    setTimeout(()=>{
                        //console.log('hide');
                        this.setState({scrollToTopShow: false});
                    },3000);
                    //todo hide when scroll back

                    /* todo 2nd solution

                    check if time ago didnt change
                    compare
                    if + show
                    if 0 hide after time
                    if - hide

                    */
                }

                if (window.pageYOffset === 0) {
                    //console.log('on top');
                    this.setState({scrollToTopShow: false});

                }


            },1000);

        };



        /*setTimeout(()=>{
            document.getElementById('pulldown-text').style.display = 'block';
            document.getElementById('pulldown').style.display = 'block';
        }, 1000*10)*/

    }

    render(){

        return(
            <div style={{

                //backgroundColor: '#fbfbfb',
                backgroundColor: '#ffffff',
                overflow: 'hidden',
                height: '100%',
                width: '100vw',

            }}
            >
                {
                    this.state.loadingScreen ?
                        <div className="loading-screen">
                            <h1>Loading ...</h1>
                        </div>
                    :
                        null
                }



                <title>Bence Vass Portfolio</title>
                <meta name="theme-color" content="#002A6A" />

                {this.state.popupShow ? <PopupRenderer message={this.state.popupMessage}
                                                       functionClose={this.closePopup}/> : null}


                {this.state.scrollToTopShow ? <ScrollToTop/> : null}



                <div id={'opt-warning'} className={'popup-bg'}
                     onClick={() => {
                         document.getElementById('opt-warning').style.display = 'none';
                     }}>
                    <div id={'warning'}>
                        <h1>Notice</h1>
                        <p>Please note, that the Desktop version is under construction.</p>
                        <p>Thank you!</p>
                    </div>
                    <p>Click to continue anyway</p>

                </div>


                <div id="nav" className={'nav-active'}>

                    <Link to="root" smooth={true} duration={400} onClick={this.navLink} style={{cursor:'pointer'}}>
                    <div id="logo">
                        <img src={logo} draggable={false}/>
                    </div>
                    </Link>

                </div>

                <div id="bg" draggable={false}>
                    <img src={shape1} id={'bg-shape1'} draggable={false}/>



                    {/*<img src={shape2} alt="shape" id={'bg-shape2'} className={'bg-shape-active selectDisable'}*/}
                    {/*draggable={false}/>*/}

                    {/*<div id={'testdiv'}>*/}
                    {/*    <img src={shape2} alt="shape" id={'testimg'} draggable={false}/>*/}
                    {/*</div>*/}



                    <img src={shape6} id="bg-shape6" draggable={false}/>
                    <img src={shape1} id="bg-shape2" draggable={false}/>
                    <img src={shape5} id="bg-shape5" draggable={false}/>
                    <img src={shape4} id="bg-shape4" draggable={false}/>
                    <img src={shape3} id="bg-shape3" draggable={false}/>

                    <div id="short-text">
                        <span>developer</span>
                        <span>designer</span>
                        <span>student</span>
                        <span>. . .</span>
                    </div>

                    <img src={fig} alt="fig" id="bg-fig" draggable={false}/>

                </div>

                <Draggable axis={"y"} bounds={{bottom: this.state.botBound, top: this.state.topBound}}
                           onStart={this.navDragStart}
                           onDrag={this.navDrag}
                           onStop={this.navDragEnd}
                           position={this.state.controlledPosition}
                           disabled={window.innerWidth >= 615}


                >
                    <div id={'navbar1'} className="backgroundImageDiv">
                        <img src={shape2} alt="shape" className={'backgroundImage'} draggable={false}/>
                        <div id={'menu'} style={{opacity: '0'}}>
                            <Link to="works" smooth={true} duration={400} onClick={this.navLink}>
                                <div className={'item'}>Projects</div>
                            </Link>
                            <Link to="comps" smooth={true} duration={400} onClick={this.navLink}>
                                <div className="item">Competitions</div>
                            </Link>
{/*                            <Link to="solutionScrollTo" smooth={true} duration={500} onClick={this.navLink}>
                                <div className={'item'}>Solutions</div>
                            </Link>*/}

                            <Link to="aboutScrollTo" smooth={true} duration={600} onClick={this.navLink}>
                                <div className={'item'}>About</div>
                            </Link>
                            <Link to="contactScrollTo" smooth={true} duration={800} onClick={this.navLink}>
                                <div className={'item'}>Contact</div>
                            </Link>

                            <a><div className={'item big'}>. . .</div></a>

                            <a><img className={'nav-icon item gap'} src={facebook} alt="facebook account" draggable={false}/></a>
                            <a><img className={'nav-icon item'} src={instagramm} alt="instagram account" draggable={false}/></a>

                        </div>

                    </div>

                </Draggable>

                <img src={pullDown} id={'pulldown'} alt="pull down" draggable={false}/>
                <p id={'pulldown-text'}>Drag Me Down</p>


                <div id="top-text">
                    <span>Hello.</span>
                    <span>I am</span>
                </div>

                <div id="name">
                    <span>Bence</span>
                    <span>Vass</span>
                </div>



                <div id="works" className={'histDisplay'}>
                    <Works/>
                </div>

                <div id="comps" className={'histDisplay'}>
                    <Competitions/>
                </div>

{/*
                <div id="solutionTop">
                    <img src={solutionTop1} id="solutionTop1" draggable={false}/>
                    <img src={solutionTop2} id="solutionTop2" draggable={false}/>
                    <img src={solutionTop3} id="solutionTop3" draggable={false}/>
                </div>

                <div id={'solutions'}>

                    <div id="solution-title">
                        <span id={'solutionScrollTo'}>Solutions</span>
                    </div>


                    <div id={'solution-container'}>

                        <img src={solutionFig} alt="" id="solution-fig" draggable={false}/>

                        <div className="solution-paragraph">
                            <div className="title">
                                Why Me?
                            </div>
                            <div className="content">
                                {this.state.sol1}
                            </div>
                        </div>

                        <div className="solution-icon">
                            <img src={solutionBulb} alt="" draggable={false}/>
                        </div>

                        <div className="solution-paragraph">
                            <div className="title">
                                What I can offer to you?
                            </div>
                            <div className="content">
                                {this.state.sol2}
                            </div>
                        </div>

                        <div className="solution-icon">
                            <img src={solutionSettings} alt="" draggable={false}/>
                        </div>

                        <div className="solution-paragraph">
                            <div className="title">
                                What you can expect?
                            </div>
                            <div className="content">
                                {this.state.sol3}
                            </div>
                        </div>

                        <div className="solution-icon">
                            <img src={solutionPuzzle} alt="" draggable={false}/>
                        </div>


                    </div>


                </div>

                <img src={solutionBot4} alt="" id="solutionBot4" draggable={false}/>
                <img src={solutionBot3} alt="" id="solutionBot3" draggable={false}/>
                <img src={solutionBot2} alt="" id="solutionBot2" draggable={false}/>
                <img src={solutionBot1} alt="" id="solutionBot1" draggable={false}/>*/}


                 <div id="about" style={{marginTop: 0}}>
                    <div id="about-title">
                        <span id={'aboutScrollTo'}>About</span>
                    </div>
                    <img src={fig3} alt="fig" id="about-fig" draggable={false}/>


                    <div className="about-paragraph">
                    <div className="title">
                        My studies
                    </div>
                    <div className="content">


                        {this.state.about1}

                    </div>
                </div>

                    <div className="about-icon">
                        <img src={study} alt="" draggable={false}/>
                    </div>

                    <div className="about-paragraph">
                        <div className="title">
                            My experiences
                        </div>
                        <div className="content">
                            {this.state.about2}

                        </div>
                    </div>

                    <div className="about-icon">
                        <img src={webDesign} alt="" draggable={false}/>
                    </div>

                    <div className="about-paragraph">
                        <div className="title">
                            My goal
                        </div>
                        <div className="content">
                            {this.state.about3}
                        </div>
                    </div>

                    <div className="about-icon">
                        <img src={idea} alt="" draggable={false}/>
                    </div>
                </div>
                {/*
                <div id="works">
                    <h1>Works</h1>
                </div>
                */}

                <div id="contact">

                    <img src={contact1} alt="" id="contact1" draggable={false}/>
                    <img src={contact2} alt="" id="contact2" draggable={false}/>
                    <img src={contact3} alt="" id="contact3" draggable={false}/>
                    <img src={contact4} alt="" id="contact4" draggable={false}/>
                    <img src={contactFig} alt="" id="contactFig" draggable={false}/>


                    <div id="contact-title">
                        <span id={'contactScrollTo'}>Contact</span>
                    </div>


                    <div id={'contact-container'}>

                        <form id={'contact-form'} onSubmit={this.handleSubmit}>
                            <label htmlFor="firstN">First name</label>
                            <input id={'firstN'} value={this.state.firstN} type="text" placeholder="Your first name"
                                   onChange={this.handleOnChange} required={true} autoComplete={'off'}/>

                            <label htmlFor="firstname">Last name</label>
                            <input id={'lastN'} value={this.state.lastN} type="text" placeholder="Your last name"
                                   onChange={this.handleOnChange} required={true} autoComplete={'off'}/>

                            <label htmlFor="email">E-mail address</label>
                            <input id={'email'} value={this.state.email} type="email" placeholder="Your e-mail address"
                                   onChange={this.handleOnChange} required={true} autoComplete={'off'}/>

                            <label htmlFor="firstname">Message</label>
                            <textarea id="message" value={this.state.message} rows="20" placeholder="Your message"
                                      onChange={this.handleOnChange} required={true} autoComplete={'off'}/>

                            <input type={'submit'} value={'Send'}/>

                        </form>

                    </div>

                </div>




            </div>
        )
    };

}


export default Page1;
