import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import {firebaseConfig} from "./config";

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const googleAnalitcs = firebaseApp.analytics();

export {db, googleAnalitcs};
