import React from 'react';
import './Navbar.css';

const Navbar = () => {
    return (
        <div id={'navbar'}>
            VASS
        </div>
    );
};

export default Navbar;