import React, {Component} from 'react';
import * as Scroll from 'react-scroll';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import arrowUp from '../../icons/svg/arrowup.svg';

class ScrollToTop extends Component {
    render() {
        return (
            <div id={'scrollToTopBtn'} onClick={()=>{scroll.scrollToTop()}}>
                <img src={arrowUp} alt="arrow-up"/>
            </div>
        );
    }
}

export default ScrollToTop;