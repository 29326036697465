import React, {Component} from 'react';

class ImageContainer extends Component {
    render() {
        return (
            <div id={'img-cont-content'}>
                <div>
                    <h1>Hello.</h1>
                    <h1>My name is</h1>
                </div>
                <div>
                    <h1>Bence</h1>
                    <h1>Vass</h1>
                </div>

            </div>
        );
    }
}

export default ImageContainer;