import React, {Component} from 'react';
import '../css/works_style.scss';

import fig from '../../img/works_fig.svg';
import figNoBG from '../../img/works_fig_nobg.svg';

import python from '../../img/works/nutrition_db/tech/python.svg';
import restf from '../../img/works/nutrition_db/tech/restframework.svg';
import django from '../../img/works/nutrition_db/tech/django.svg';
import bootstrap from '../../img/works/nutrition_db/tech/bootstrap.svg';
import firebase from '../../img/works/ai_insight/tech/firebase.svg';
import reactjs from '../../img/works/ai_insight/tech/reactjs.svg';
import tf from '../../img/works/ai_insight/tech/tf.svg';
import tfjs from '../../img/works/ai_insight/tech/tfjs.svg';
import aws from '../../img/works/laborart/tech/aws.svg';
import apollo from '../../img/tech/APOLLO.svg';
import certbot from '../../img/tech/certbot.svg';
import docker from '../../img/tech/docker.svg';
import graphene from '../../img/tech/graphene.svg';
import graphql from '../../img/tech/graphql.svg';
import jwt from '../../img/tech/jwt.svg';
import nginx from '../../img/tech/nginx.svg';
import stlyedC from '../../img/tech/stzledcomponents.svg';
import redux from '../../img/tech/redux.svg';
import antD from '../../img/tech/antdesign.svg';
import framerMotion from '../../img/tech/framermotion.svg';


import nutritionDBImage from '../../img/works/nutrition_db/nutritiondb.webp';
import nutritionDBFallback from '../../img/works/nutrition_db/nutritiondb.xyz_-min.png';
import aiInsight from '../../img/works/ai_insight/aiinsight.webp';
import aiInsightFallback from '../../img/works/ai_insight/ai_insight.png';
import laborartImage from '../../img/works/laborart/laborart.webp';
import laborartImageFallback from '../../img/works/laborart/laborart.xyz_-min.png';
import graphqlauthImage from '../../img/works/graphqlauth/graphqlauth.webp';
import graphqlauthImageFallback from '../../img/works/graphqlauth/graphqlauth.png';


import unterwegzImage from '../../img/works/unterwegz/unterwagz.webp'
import unterwegzFallbackImage from '../../img/works/unterwegz/unterwagz.png'
import unterwegzPartnerImage from '../../img/works/unterwegz/unterwagzpartner.webp'
import unterwegzPartnerFallbackImage from '../../img/works/unterwegz/unterwagzpartner.png'

import seminarArbeitImage from '../../img/works/seminararbeit/inter_ml.png'



import {db} from "../firebase";


class WorkCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            showContent: false,
            works: [],
        }
    }

    componentDidMount() {
        this.h = this.infoCard.clientHeight * 1.4;
        this.infoCard.style.maxHeight = '0';

    }

    render() {
        return (
            <div className="paragraph" onClick={(e) => {
                //console.log('open work');
                //this.setState({collapsed: !this.state.collapsed})
                if (this.state.showContent) {
                    this.infoCard.style.maxHeight = '0';
                } else {
                    this.infoCard.style.maxHeight = this.h + 'px';
                }
                this.setState({showContent: !this.state.showContent});

                //e.target.style.maxHeight = '0';
            }}>
                <div className="title">{this.props.title}</div>
                <div className="image">
                    <picture draggable={false}>
                        <source srcSet={this.props.image} type={'image/webp'}/>
                        <source srcSet={this.props.fallbackImg} type={'image/png'}/>
                        <img src={this.props.fallbackImg} alt="works" draggable={false}/>
                    </picture>
                </div>
                {this.state.collapsed ? null :
                    <div ref={(c) => {
                        this.infoCard = c
                    }} className='info-card'>
                        <div className="content">{this.props.content}</div>
                        <div className="publish">Published: {this.props.publish}</div>
                        < div className="tech">
                            {this.props.tech}
                        </div>
                        {this.props.url ?
                            <a href={this.props.url} target={'_blank'} rel="noopener noreferrer">
                                <div className="visit-btn">
                                    <div className="btn">Visit</div>
                                </div>
                            </a>
                            :
                            null
                        }
                    </div>
                }
            </div>
        )
    }
}


class Works extends Component {

    render() {
        return (
            <div>
                <div className="main-title"><span>Projects</span></div>
                <div className="fig"><img src={figNoBG} alt="works bence vass" draggable={false}/></div>

                <WorkCard
                    title='Seminararbeit: Interpretable Machine Learning'
                    image={seminarArbeitImage}
                    fallbackImg={seminarArbeitImage}
                    content="In my Bachelor of Science term paper, I summarized Chapter 10 of Christoph Molnar's book, 'Interpretable Machine Learning.' This chapter covers the mathematical foundations of various concepts from a wide range of research papers, providing an excellent overview of the topic in machine learning. I also created examples using TensorFlow to illustrate these concepts."
                    url='https://www.kaggle.com/ben2885/seminararbeit-interpretable-machine-learning'
                    tech={
                        <div>
                            <img src={tf} alt="tensorflow"/>
                        </div>
                    }
                    publish={'2024 Q2'}

                />


                <WorkCard
                    title='Unterwegz Partner (Beta)'
                    image={unterwegzPartnerImage}
                    fallbackImg={unterwegzPartnerFallbackImage}
                    content='
                    Application, which receive the data from the customers created in Unterwegz app.
                    It is capable of receiving notification via firebase cloud messaging. It is using ant
                    -design components to display data, styled-components to customize the appearance and
                    Framer-Motion for animation in order to improve overall user-experience. In the back-end
                    uses firebase services as Unterwegz app.
                    (Please consider, this application is under development, the hosted content may change with time)

                    '
                    url='https://findme-provider.web.app/'
                    tech={
                        <div>
                            <img src={reactjs} alt="react js"/>
                            <img src={firebase} alt="firebase"/>
                            <img src={redux} alt="redux"/>
                            <img src={stlyedC} alt="styled-compoents"/>
                            <img src={framerMotion} alt="framer-motion"/>
                            <img src={antD} alt="ant-design"/>

                        </div>
                    }
                    publish={'2021 Q1'}

                />
                <WorkCard
                    title='Unterwegz (Beta)'
                    image={unterwegzImage}
                    fallbackImg={unterwegzFallbackImage}
                    content='
                    Web application made with React.js, which allows users to share their location and
                    addition data with partners. The app uses firebase host for hosting, firestore for
                    database and firebase for authentication. Uses firebase cloud functions to create
                    notification. Use custom written components made with styled-components.
                    (Please consider, this application is under development, the hosted content may change with time)
                    '
                    url='https://findme-283114.web.app/'
                    tech={
                        <div>
                            <img src={reactjs} alt="react js"/>
                            <img src={firebase} alt="firebase"/>
                            <img src={redux} alt="redux"/>
                            <img src={stlyedC} alt="styled-compoents"/>


                        </div>
                    }
                    publish={'2021 Q1'}

                />


                <WorkCard
                    title="GraphQlAuth"
                    image={graphqlauthImage}
                    fallbackImg={graphqlauthImageFallback}
                    content='
                    Project modelling the communication between the React.js frontend app with the use
                    of Apollo package, through GraphQl API, with the django backend application, written
                    with graphene package, hosted with Docker and used nginx as a reverse proxy. Pass
                    TLS certificate with certbot container and use JWT for security measure.
                    '
                    url='https://github.com/bence-vass/GraphQlAuth'
                    tech={
                        <div>
                            <img src={python} alt="python"/>
                            <img src={django} alt="django"/>
                            <img src={aws} alt="aws"/>
                            <img src={apollo} alt="apollo"/>
                            <img src={docker} alt="docker"/>
                            <img src={certbot} alt="certbot"/>
                            <img src={graphene} alt="graphene"/>
                            <img src={graphql} alt="graphql"/>
                            <img src={jwt} alt="jwt"/>
                            <img src={nginx} alt="nginx"/>
                        </div>
                    }
                    publish={'2020 Q2'}

                />
                <WorkCard
                    title='AI inSight'
                    image={aiInsight}
                    fallbackImg={aiInsightFallback}
                    content='
                    Deep learning project with TensorFlow and TensorFlow.js, based on the MNIST Handwriting Dataset.
                     A step-by-step client-side handwriting recognition computation visualizer implemented in a
                      React.js App hosted by Firebase.
                    '
                    url='https://ai-vision2020.web.app/'
                    tech={
                        <div>
                            <img src={tfjs} alt="tensorflow js"/>
                            <img src={reactjs} alt="react js"/>
                            <img src={firebase} alt="firebase"/>
                            <img src={tf} alt="tensorflow"/>

                        </div>
                    }
                    publish={'2020 Q1'}

                />
                <WorkCard
                    title='Nutrition Database'
                    image={nutritionDBImage}
                    fallbackImg={nutritionDBFallback}
                    content='
                     Python Django based website and Rest API, with use of the Django Rest Framework, implementing
                     different permission classes and token authentication. Hosted on an AWS instance, with basic Nginx.'
                    //url='http://nutritiondb.xyz/'
                    tech={
                        <div>
                            <img src={python} alt="python"/>
                            <img src={django} alt="django"/>
                            <img src={bootstrap} alt="bootstrap"/>
                            <img src={restf} alt="restframework"/>
                        </div>
                    }
                    publish={'2019 Q3'}


                />
                <WorkCard
                    title="Labor'artorium"
                    image={laborartImage}
                    fallbackImg={laborartImageFallback}
                    content='
                    Django blog originally created for the teacher and students of a private art class,
                    with a basic posting system. The teacher can create different events and highlighted posts,
                     while the students can post their own artworks.
                    '
                    //url='http://laborart.xyz/'
                    tech={
                        <div>
                            <img src={python} alt="python"/>
                            <img src={django} alt="django"/>
                            <img src={aws} alt="aws"/>
                        </div>
                    }
                    publish={'2019 Q4'}
                />
            </div>
        );
    }
}

export default Works;
