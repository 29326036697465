import React, {Component} from 'react';
import Navbar from '../navbar/Navbar';



class Page2 extends Component {

    ContainersIDs = [
        'h-description',
        'h-about',
        'h-skills',
        'h-contact',
    ];

    state = {
        containerPaddingTop: 60,
        heights: [],
    };

    onClickSkills = async () => {

        let element = await document.getElementById('h-skills');

        let offsetTop = await this.state.heights.skills - element.clientHeight;

        console.log(offsetTop);

        element.style.height = (await this.state.heights.skills - await this.state.containerPaddingTop) + 'px';
        //document.getElementById('h-skills').style.height ='';




        let props = window.getComputedStyle(await element).top;
        console.log(props);

        element.style.top = -296 + 'px';


        let prevEl = await document.getElementById('h-contact');

        prevEl.style.top = -356 + 'px';


            setTimeout(async ()=>{
                element.style.transition = 'top 0.5s ease-in-out';
                prevEl.style.transition = 'top 0.5s ease-in-out';
                element.style.top = -240 + 'px';
                prevEl.style.top = -300 + 'px';

                await this.setState({showSkills: ! await this.state.showSkills});
            //document.getElementById('h-skills').style.height ='';


        }, 1500);


    };

    expandCollapse = async (id) => {

        let element = document.getElementById(this.ContainersIDs[id]);


        if (await this.state[this.ContainersIDs[id]]){

            console.log(id, 'true');


        } else {
            console.log(id, 'false');
            let defaultHeight = element.clientHeight;
            let defaultTop = parseInt(window.getComputedStyle(element).top);
            console.log(element.clientHeight);
            console.log(await this.state.heights[this.ContainersIDs[id]]);


            let title = element.getElementsByClassName('container-title')[0];
            //title.style.opacity = await '1';

            title.style.transition = await 'opacity 0.3s ease-in-out';
            title.style.opacity = await '0';



            await setTimeout(async ()=>{
                console.log('ready');
                element.style.height = (await this.state.heights[this.ContainersIDs[id]] - await this.state.containerPaddingTop) + 'px';
                console.log(defaultTop);
                element.style.top = (defaultTop - (await this.state.heights[this.ContainersIDs[id]] - defaultHeight)) + 'px';

                for (let i=id+1; i < this.ContainersIDs.length; i++){
                    let offsetTop = -60 * (i + 2) - (await this.state.heights[this.ContainersIDs[id]] - defaultHeight);
                    document.getElementById(this.ContainersIDs[i]).style.top = offsetTop + 'px';

                }

                await this.setState({[this.ContainersIDs[id]]: true});

                setTimeout(async ()=>{
                    let content = element.getElementsByClassName('container-content')[0];
                    content.style.opacity = '1';
                    element.style.transition = await 'top 0.3s ease-in-out';
                    element.style.top = await  -(id + 2) * 60 + 'px';
                    for (let i=id+1; i < this.ContainersIDs.length; i++){
                        let offsetTop = -60 * (i + 2);
                        document.getElementById(this.ContainersIDs[i]).style.top = offsetTop + 'px';
                        document.getElementById(this.ContainersIDs[i]).style.transition = await 'top 0.3s ease-in-out';

                    }
                },300);


                element.style.transition = '';



                console.log('ready1');



            }, 300);




            //console.log(((-60 * (id + 2 + 1)) - (await this.state.heights[this.ContainersIDs[id]] - defaultHeight)), 't');
            //document.getElementById('h-about').style.top = ((-60 * (id + 2 + 1)) - (await this.state.heights[this.ContainersIDs[id]] - defaultHeight)) + 'px'
        }



    };

    componentWillMount() {
        console.log('will');
        for(let i=0; i < this.ContainersIDs.length; i++){
            this.setState({[this.ContainersIDs[i]]: true});
        }
    }

    async componentDidMount() {
        //let elH = await document.getElementById('h-skills').clientHeight;
        //this.setState({heights: {skills: elH}});

        //console.log(await this.state.heights.skills);

        //this.setState({showSkills: false});
        //document.getElementById('h-skills').style.height = '13px';


        for(let i=0; i < this.ContainersIDs.length; i++){
            console.log(this.ContainersIDs[i]);
            let elementExpandedHeight = await document.getElementById(this.ContainersIDs[i]).clientHeight;
            console.log(elementExpandedHeight);
            await this.setState(prevState => ({heights:{...prevState.heights, [this.ContainersIDs[i]]: elementExpandedHeight}}));
            this.setState({[this.ContainersIDs[i]]: false});

        }

        console.log(await this.state)


    }


    render() {
        return (
            <div style={{
                backgroundColor:'red',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
            }}>

                <Navbar/>

                <div id={'h-image-container'} className={'home-container'}>
                    <div id={'home-image'}></div>
                </div>

                <div id={this.ContainersIDs[0]} className={'home-container'} onClick={event => this.expandCollapse(0)}>
                    {
                        this.state[this.ContainersIDs[0]] ?

                            <div id={'h-ski'} className={'container-content'}>
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                            </div>

                            :

                            <div className={'container-title'}>What I do</div>

                    }
                </div>

                <div id={this.ContainersIDs[1]} className="home-container" onClick={event => this.expandCollapse(1)}>
                    {
                        this.state[this.ContainersIDs[1]] ?

                            <div id={'h-ski'} className={'container-content'}>
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                            </div>

                            :

                            <div className={'container-title'}>About Me</div>

                    }
                </div>

                <div id={this.ContainersIDs[2]} className="home-container" onClick={this.onClickSkills}>

                    {
                        this.state[this.ContainersIDs[2]] ?

                            <div id={'h-ski'} className={'container-content'}>
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                            </div>

                        :

                            <div className={'container-title'}>Skills</div>

                    }


                </div>

                <div id={this.ContainersIDs[3]} className="home-container">
                    {
                        this.state[this.ContainersIDs[3]] ?

                            <div id={'h-ski'} className={'container-content'}>
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                                Those are my skills
                            </div>

                            :

                            <div className={'container-title'}>Contact</div>

                    }
                </div>

            </div>
        );
    }
}

export default Page2;