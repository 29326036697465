import React from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Redirect, Switch} from "react-router-dom";
import {connect} from "react-redux";
import SwipeableRoutes from 'react-swipeable-routes';

import Page1 from './components/pages/Page1';
import Page2 from './components/pages/Page2';
import Page3 from './components/pages/Page3';
import Test from './components/pages/Test';
import Desc from './components/homepage-components/skills';
import CarouselPage from './components/pages/CarouselPage'

function App() {



  return (
      <Router>

          {/*<SwipeableRoutes
              enableMouseEvents
              containerStyle={{minHeight:'100vh', width:'100vw', height:'100%'}}
          >*/}


                  <Route exact path={'/'} component={Page1}/>

{/*
                  <Route exact path={'/'} component={CarouselPage}/>
*/}
              {/*<Route exact path={'/news'} component={Page3}/>*/}



          {/*</SwipeableRoutes>*/}

          {/*<Route exact path={'/'} component={Test}/>*/}
      </Router>
  );
}

const mapStateToProps = (state) => {
    return{
        posts: state.posts
    }
};

export default connect(mapStateToProps)(App);
