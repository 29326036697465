import React, {Component} from 'react';

class Description extends Component {
    render() {
        return (
            <div>
                I am programing websites for start-ups and small businesses. Design UI/UX. Guidance for remarkable
                and unique image for your company which describe the best your company's activity and reflect
                your proficiency and passion it.
                <br/>
                I am a one-man army, I can do front- and backend programing.
                Due to my experience in Adobe Photoshop and Illustrator I can even help you to design your
                corporate identity and create a brand new or a renewed image.
                <br/>
                I am most familiar with Python and JavaScript programing language. I have been coding those two since
                the beginning of my programmer career.

            </div>
        );
    }
}

export default Description;