import React, {Component} from 'react';

import {Carousel} from "antd";
import 'antd/dist/antd.css';


import '../css/works_style.scss';

import fig from '../../img/works_fig.svg';
import figNoBG from '../../img/works_fig_nobg.svg';

import python from '../../img/works/nutrition_db/tech/python.svg';
import restf from '../../img/works/nutrition_db/tech/restframework.svg';
import django from '../../img/works/nutrition_db/tech/django.svg';
import bootstrap from '../../img/works/nutrition_db/tech/bootstrap.svg';
import firebase from '../../img/works/ai_insight/tech/firebase.svg';
import reactjs from '../../img/works/ai_insight/tech/reactjs.svg';
import tf from '../../img/works/ai_insight/tech/tf.svg';
import tfjs from '../../img/works/ai_insight/tech/tfjs.svg';
import aws from '../../img/works/laborart/tech/aws.svg';
import apollo from '../../img/tech/APOLLO.svg';
import certbot from '../../img/tech/certbot.svg';
import docker from '../../img/tech/docker.svg';
import graphene from '../../img/tech/graphene.svg';
import graphql from '../../img/tech/graphql.svg';
import jwt from '../../img/tech/jwt.svg';
import nginx from '../../img/tech/nginx.svg';
import stlyedC from '../../img/tech/stzledcomponents.svg';
import redux from '../../img/tech/redux.svg';
import antD from '../../img/tech/antdesign.svg';
import framerMotion from '../../img/tech/framermotion.svg';


import yettel_P1 from '../../img/comps/yettel/pic1.jpg'
import yettel_P2 from '../../img/comps/yettel/pic2.png'
import yettel_P3 from '../../img/comps/yettel/pic3.jpg'
import yettel_P4 from '../../img/comps/yettel/pic4.png'
import yettel_P5 from '../../img/comps/yettel/pic5.jpg'

import hack3_P1 from '../../img/comps/hack3/pic1.png'
import hack3_P2 from '../../img/comps/hack3/pic2.png'

import bosch_P1 from '../../img/comps/bosch/pic1.jpg'

import crafthack_P1 from '../../img/comps/crafthack/pic1.png'

import crafthack3_23_P1 from '../../img/comps/crafthack3-2023/pic1.png'
import crafthack3_23_P2 from '../../img/comps/crafthack3-2023/pic2.png'



import mshack_23_P1 from '../../img/comps/mshack-2023/pic1.png'
import mshack_23_P2 from '../../img/comps/mshack-2023/pic2.png'
import mshack_23_P3 from '../../img/comps/mshack-2023/pic3.png'



import aiInsight from '../../img/works/ai_insight/aiinsight.webp';
import aiInsightFallback from '../../img/works/ai_insight/ai_insight.png';
import laborartImage from '../../img/works/laborart/laborart.webp';
import laborartImageFallback from '../../img/works/laborart/laborart.xyz_-min.png';
import graphqlauthImage from '../../img/works/graphqlauth/graphqlauth.webp';
import graphqlauthImageFallback from '../../img/works/graphqlauth/graphqlauth.png';


import unterwegzImage from '../../img/works/unterwegz/unterwagz.webp'
import unterwegzFallbackImage from '../../img/works/unterwegz/unterwagz.png'
import unterwegzPartnerImage from '../../img/works/unterwegz/unterwagzpartner.webp'
import unterwegzPartnerFallbackImage from '../../img/works/unterwegz/unterwagzpartner.png'

import {db} from "../firebase";


class CompCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            showContent: false,
            works: [],
        }
    }

    componentDidMount() {
        this.h = this.infoCard.clientHeight * 1.4;
        this.infoCard.style.maxHeight = '0';

    }

    render() {
        return (
            <div className="paragraph" onClick={(e) => {
                //console.log('open work');
                //this.setState({collapsed: !this.state.collapsed})
                if (this.state.showContent) {
                    this.infoCard.style.maxHeight = '0';
                } else {
                    this.infoCard.style.maxHeight = this.h + 'px';
                }
                this.setState({showContent: !this.state.showContent});

                //e.target.style.maxHeight = '0';
            }}>
                <div className="title">{this.props.title}</div>
                <div className="challenge">
                    ( {this.props.challengeName}{this.props.challengeName && this.props.placement ? " - " : null}<span style={{fontWeight: 'bold'}}>{this.props.placement}</span> )
                </div>
                <div className="projectName">{this.props.projectName}</div>
                {this.props.pictures ?
                    this.props.pictures.length > 1 ?
                        <Carousel autoplay>
                            {this.props.pictures.map(e => {
                                return (
                                    <div>
                                        <div className="image">
                                            <picture draggable={false}>
                                                <source srcSet={e}/>
                                                <img src={e} alt={this.props.challengeName} draggable={false}/>
                                            </picture>
                                        </div>
                                    </div>
                                )
                            })}
                        </Carousel>
                        :
                        <div className="image">
                            <picture draggable={false}>
                                <source srcSet={this.props.pictures[0]}/>
                                <img src={this.props.pictures[0]} alt="works" draggable={false}/>
                            </picture>
                        </div>
                    :
                    null

                }

                {this.state.collapsed ?
                    null
                    :
                    <div ref={(c) => {
                        this.infoCard = c
                    }} className='info-card'>
                        <div className="content">{this.props.content}</div>
                        <div className="quote" dangerouslySetInnerHTML={{__html: this.props.quote}}></div>

                        <div className="publish">Date: {this.props.date}</div>

                        {this.props.url ?
                            <a href={this.props.url} target={'_blank'} rel="noopener noreferrer">
                                <div className="visit-btn">
                                    <div className="btn">Visit</div>
                                </div>
                            </a>
                            :
                            null
                        }
                    </div>
                }
            </div>
        )
    }
}


class Competions extends Component {

    render() {
        return (
            <div>
                <div className="main-title"><span>Competitions</span></div>
                <div className="fig"><img src={figNoBG} alt="works bence vass" draggable={false}/></div>


                <CompCard
                    title={'Microsoft’s AI Classroom University Hackathon'}
                    placement={'Participant'}
                    projectName={'ELEXAM'}
                    pictures={[mshack_23_P1,mshack_23_P2,mshack_23_P3]}
                    content={'This project involves creating a prototype for classroom engagement using accessible eye-tracking technology via webcams. The framework includes intuitive interfaces for teachers and students, adaptive features like customizable thresholds, and automated interventions to boost engagement. The focus is on simplicity, adaptability, and cross-device compatibility for effective integration in diverse learning environments.'}
                    quote={'<h3>The Inspiration </h3>\n' +
                        '                <p>\n' +
                        '                    In a world dominated by sensory overload from social media, the younger generation struggles\n' +
                        '                    with diminished attention spans, making it harder for them to acquire knowledge and concentrate\n' +
                        '                    on lessons. However, the solution lies not in rebuilding the entire educational system but in\n' +
                        '                    integrating these stimuli into modern teaching methods.\n' +
                        '                </p>\n' +
                        '                <p>\n' +
                        '                    Our project exemplifies this approach,\n' +
                        '                    demonstrating how these distractions can be harnessed to enhance focus and engagement. By adapting\n' +
                        '                    rather than overhauling, we present a middle ground that empowers learners without sacrificing the\n' +
                        '                    benefits of the digital age.\n' +
                        '                </p>\n' +
                        '                <p>Let us inspire change, creating an educational paradigm that transforms\n' +
                        '                    challenges into opportunities for growth. Through this innovative perspective, we equip the younger\n' +
                        '                    generation with the skills to navigate the complexities of the modern world with focus and a genuine\n' +
                        '                    passion for learning.\n' +
                        '                </p>\n' +
                        '                <h3>What it does</h3>\n' +
                        '                <p>New approach to acquire knowledge outside of the school:</p>\n' +
                        '                <ol>\n' +
                        '                    <li>\n' +
                        '                        Helps engaging in the topics: - Generating sensory stimulus learning material.\n' +
                        '                        - Uses already existing teaching books.\n' +
                        '                    </li>\n' +
                        '                    <li>\n' +
                        '                        Tailored Learning with Technology: -\n' +
                        '                        Utilize generative AI to tailor content, making it more consumable for\n' +
                        '                        individuals with varying attention spans. - Envision a new approach through a\n' +
                        '                        roadmap that integrates picture-to-learning transformations powered by Azure Cloud and OpenAI.\n' +
                        '                    </li>\n' +
                        '                    <li>\n' +
                        '                        Feedback-Driven Personalization: - Integrate Webgazer for real-time feedback on screen\n' +
                        '                        engagement,\n' +
                        '                        using heatmaps to identify and improve the most effective content areas. - Employ AI-generated\n' +
                        '                        quizzes\n' +
                        '                        to measure and adapt the effectiveness of learning sessions, providing a personalized learning\n' +
                        '                        journey based on individual styles through machine learning classification.\n' +
                        '                    </li>\n' +
                        '                </ol>\n' +
                        '                <h3>How we built it For</h3>\n' +
                        '                <p>technologies we used:</p>\n' +
                        '                <ul>\n' +
                        '                    <li>\n' +
                        '                        [React](https://react.dev/)\n' +
                        '                    </li>\n' +
                        '                    <li>\n' +
                        '                        [Webgazer.js](https://webgazer.cs.brown.edu/)\n' +
                        '                    </li>\n' +
                        '                    <li>\n' +
                        '                        [Heatmap.js](https://www.patrick-wied.at/static/heatmapjs/)\n' +
                        '                    </li>\n' +
                        '                </ul>\n' +
                        '\n' +
                        '                <h3>Challenges we ran into </h3>\n' +
                        '                <p>\n' +
                        '                    Our biggest drawback, is the fact, that most of\n' +
                        '                    the Azure services we wanted to use, are only available for enterprise usage,\n' +
                        '                    so we had to use dummy data in most cases.\n' +
                        '                </p>\n' +
                        '\n' +
                        '\n' +
                        '                <h3>Accomplishments that we&lsquo;re proud of</h3>\n' +
                        '                <p>\n' +
                        '                    Our teaching Demo speaks for itself. It is responsive, and modern approach to showcase,\n' +
                        '                    how can we engage younger generation&lsquo;s attention spam problems. By using our heatmap as the basis of\n' +
                        '                    the engagement feedback, we can customize the learning experience for the different learning styles\n' +
                        '                    that\n' +
                        '                    fits the best our students.\n' +
                        '                </p>\n' +
                        '\n' +
                        '                <h3>What we learned</h3>\n' +
                        '                <p>\n' +
                        '                    We learned a lot about Azure Web Services, and how\n' +
                        '                    colourful\n' +
                        '                    is the palette of available tools provided by Microsoft.\n' +
                        '                </p>\n' +
                        '\n' +
                        '                <h3>What&lsquo;s next for ELEXAM</h3>\n' +
                        '                <p>\n' +
                        '                    We would like to\n' +
                        '                    automate the whole functioning of the website. - using generative AI to create content, - automate\n' +
                        '                    the\n' +
                        '                    functioning, so the student doesn&lsquo;t feel overwhelmed by the learning process, - make it available\n' +
                        '                    for\n' +
                        '                    teachers, to help use it as a teaching aid.\n' +
                        '                </p>'}
                    url={'https://salmon-coast-097532810.4.azurestaticapps.net/'}
                    date={'2023 Q4'}
                />





                <CompCard
                    title='CraftHack 2023'
                    challengeName={'Hack&Heal'}
                    placement={'Finalist'}
                    projectName={'RapAID'}
                    pictures={[crafthack3_23_P1, crafthack3_23_P2]}
                    content='Optimization of medical resources, some suggestion of more relevant metrics, and a possible solution for the lack of examination machines and personnel with a closer cooperation of the private and public healthcare systems'
                    quote="
<b>The Problem</b></br>
People often face long waiting lists for MRI or CT scans, causing significant stress. The average waiting time for an MRI is over 2/3 of a year, while for a CT scan, it's nearly 3 months (source). This issue stems from various factors such as financial constraints, labor shortages, limited resources in the healthcare sector, and the need to reallocate existing resources.

</br></br><b>Transparency</b></br>
To address the lack of transparency in waiting lists, we propose creating a new list that prioritizes patients based on processed data, taking into account relevant inputs without revealing specific information. Our alternative approach relies on patient consent. However, transparency is not the only problem; the distribution of wealth has hindered the full potential of both public and private healthcare systems.

</br></br><b>Our solution</b></br>
To alleviate strain on the overwhelmed public healthcare sector, we present a groundbreaking solution. By engaging the private sector, we can prevent unnecessary fatalities. Private facilities can contribute to the common good and receive tax subsidies in return. Our role would be to connect both parties through our application.

</br></br><b>The algorithm</b></br>
Our envisioned algorithm provides independent results, ensuring transparency and reliability. It considers factors such as age, disease severity, chance of healing, and tumor growth rate if applicable. Our application is not limited to providing services but also offers an API for integration into existing healthcare frameworks. Patients can choose whether to publish their processed health data and trust us with their medical documents.

</br></br><b>The scalability</b></br>
Expanding beyond national boundaries, we aim to tap into the potential of the European healthcare system. We can offer more optimal and comfortable alternatives for individuals who may be geographically isolated from their national facilities but closer to foreign ones. Our goal is to provide a smoother experience for these patients.
For companies with stronger financial capabilities, we enable a seamless transition between national and international or public and private sectors. They can finance medical examinations in advance and receive reimbursement from public health insurance later.

</br></br><b>Summary</b></br>
In summary, the extended waiting times for MRI and CT scans pose significant challenges. Our proposal involves a transparent yet privacy-preserving approach, leveraging the private sector's support to improve healthcare access. With an independent and reliable algorithm, we aim to provide personalized care, integration with existing systems, and opportunities for patients to make informed decisions. By expanding services beyond borders, we can enhance the overall healthcare experience."
                     url='https://shorturl.at/mtyPQ'
                    date={'2023 Q2'}
                />




                <CompCard
                    title='K&H HACK3 - Hybrid hackathon'
                    challengeName={'K&H challange'}
                    placement={'Participant'}
                    projectName={'parkURmoney'}
                    pictures={[hack3_P1, hack3_P2]}
                    content='Optimize company park spot distributions with cutting-edge technologies to address future office culture’s challenges and eliminate the inefficiency caused by the hybrid workspace model
'
                    quote="The biggest obstacle to be overcome by the K&H parking app is the motivation of employees to use it. According to our team's thinking, the most important thing was to develop a suitable motivation system, in addition to providing them with an intuitive and easily expandable interface, which is adaptive to the user's needs, can be used for fast and slow, but predictable bookings. Sustainability in the application also received special attention, so all actions aimed at this are encouraged with special importance, so that these functions were integrated into the system to ensure continuity. But what is our idea? This is explained in detail in the following chapters.

</br></br><b>The basis of the concept</b></br>
Our first observation after seeing the task was that K&H is a financial institution. Accordingly, a solution tailored to the financial institution is required for the problem. What goes on in the minds of bank employees. In addition to being analytical and reliable, they are also full of competitive spirit. And what better way to whip up this competitive spirit in them than
some familiar phenomenon in which they already have sufficient experience. The stock market. It requires demand, supply, liquidity and a properly enforced set of rules. This is what we created
in use, but instead of securities, parking spaces are at the center of trading. In this way, we essentially provide part of the motivation to use the application with a kind of gamification."
                    url='https://github.com/bence-vass/HACK3-ParkingApp'
                    date={'2023 Q1'}
                />

                <CompCard
                    title='Yettel - Hack it your way'
                    challengeName={'Energy efficiency in office and home challenge'}
                    placement={'Challenge Winner'}
                    projectName={'UpWind'}
                    pictures={[yettel_P3, yettel_P2, yettel_P1, yettel_P4, yettel_P5]}
                    content='
                    Designs of the sensors and their ecosystem with the underlying app that allows them to control the airflow for a broad user base. Possible integration to an existing Smart Home network (improved version of THERMA-FLOW)'
                    date={'2022 Q4'}
                />


                <CompCard
                    title='Code #LikeABosch 2022'
                    challengeName={'Hardware development challenge'}
                    placement={'Challenge Finalist'}
                    projectName={'THERMA-FLOW'}
                    pictures={[bosch_P1,]}
                    content='Retrofittable device that allows the consumers to cut expenses on heating costs, with precise control of the airflow inside their residences and with psychologically proven methods in order to its increase one’s heat comfort.
'
                    quote="
                    Using less energy is always a good idea. We created an IoT smart-grid for air quality measuring, with this, we can control air ventilation, achieving financial savings, and health benefits and also it helps to prevent mold. We are using perception illusions to gain thermal comfort.
                    For more details, here is the
                    link for the <a href='https://github.com/bence-vass/CodeLikeABosch/blob/main/thermaflow4.pdf' target='_blank'>one-pager</a>,
                     and also the <a href='https://github.com/bence-vass/CodeLikeABosch/blob/main/Thermaflow-2.docx' target='_blank'>full documentation</a>.
                    "
                    url='https://github.com/bence-vass/CodeLikeABosch'
                    date={'2022 Q4'}
                />


                <CompCard
                    title="CraftHack hybrid hackathon 2022"
                    challengeName={'Antavo Challenge'}
                    placement={'Challange Winner'}
                    projectName={'DyLos'}
                    pictures={[crafthack_P1,]}
                    content='
                    A loyalty program that provides relevant marketing data
                    for each customer, represented in a way that they prefer
                    with in an existing framework of loyalty systems
                    '
                    quote="Personalized, dynamic, captivating. Forget about
                     all the useless advertisment e-mails, spams or unwanted notifications.
                     2D AI will provide dynamically changing promotions, customized tiered programs, etc.,
                     so the companies (e.g KFC) could provide their customers the most preferred loyalty program. </br></br>
                     A dynamic loyalty system ran by a 2D AI</br></br>
                    <b>Abstract</b></br>
Antavo offers a wide variety of loyalty services from which a customercan choose what fits best for them. But the days of one-size-fits-all are gone. We offer a dynamically changing 2D AI modul that’s designed for both the customer (e.g. KFC) and the end user (e.g. KFC customer) to maximalize loyalty service's profit for both parties.

                   </br> <b>Customer’s view</b></br>
                Antavo’s customers (like KFC, Biotech) will be able to adjust the AI according to marketing preferences and desired brand positioning. The modul will make sure to choose the best service combination to maximise the profit. The customer will have a better control over their brand to be able to increase their revenue with the least effort possible. They will earn higher revenue compared to the previous program offered by the platform.

                </br><b>End user’s view</b></br>
                The customers serves people from diverse backgrounds and they are all different, just as our solution. Some prefer coupons, some likes to feel exclusive. Our system will take their preferences into consideration, when choosing the loyalty service type, this way their desires will be fulfilled without even noticing it.

                </br><b>Software side</b></br>
                Our solution is easily integrable, thanks to its own GraphQl API Gateway. As for being highly scalable we use non sequential database, which is capable of storing enormous amount of user data. To solve the problem of high request rate we are utilising Docker Orchastration so that the horizontal scalabilty wouldn't have any limitations. The service could be handled as an optional extension to the existings counterparts to optimze the effectivness of loyality programs. Our backend server would provide an AI based solution which could live up to worldwide industry standards. We're planning to make a Dynamic Loyalty System by constantly monitoring the behavior of the customers. With this method, they could get the best discounts, e.g:
                if someone likes to order food rather than eating at the restaurant, they could get more deduction on home deliveries. The AI could evaluate the user's preferences, but also the optimal loyalty program, so each user would have their personal experience.

                </br><b>Why is it profitable?</b></br>
                The application of the artificial intelligence could eliminate the unnecessary expenses on behalf of the service’s costumer. On the other hand it is capable to provide adequate content for different target groups. Therefore the end user could enjoy an overall more pleasant experience to enhance further purchases, and build up a loyalty towards certain brands.

                </br><b>Summary</b></br>
                Our product provides such a solution, that has never been implemented in this segment.
                        With this, Antavo could step up their loyalty program.
                        "
                    url='https://github.com/bence-vass/DyLos'
                    date={'2022 Q2'}
                />


            </div>
        );
    }
}

export default Competions;
