import React, {Component} from 'react';
import '../css/input.scss';

import {db} from "../firebase";

class Contact extends Component {



    constructor(props) {
        super(props);
        this.state = {
            firstN: '',
            lastN: '',
            email: '',
            massage: '',
        };

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };



    handleSubmit(e){
        e.preventDefault();
        e.stopPropagation();
      console.log('submitted');
      db.collection('email-test').doc(String(new Date().getTime())).set({
          date: new Date(),
          firstN: this.state.firstN,
          lastN: this.state.lastN,
          email: this.state.email,
          massage: this.state.massage,
      }).then(()=>{
          console.log('success');
      }).catch((err)=>{
          console.log(err)
      });

    };


    handleOnChange(e){
      this.setState({[e.target.id]: e.target.value})
    };


    render() {
        console.log(this.state);
        return (
            <div id={'contact-input'} onClick={(e)=>{
                e.stopPropagation();
            }}>

                <form className="Wrapper" onSubmit={this.handleSubmit}>
                    <div className="Input">
                        <input type="text" id="firstN" className="Input-text"
                               placeholder="Your first name" value={this.state.firstN}
                               onChange={this.handleOnChange} required={true} autoComplete={'off'}/>
                            <label htmlFor="input" className="Input-label">First name</label>
                    </div>
                    <div className="Input">
                        <input type="text" id="lastN" className="Input-text"
                               placeholder="Your last name" value={this.state.lastN}
                               onChange={this.handleOnChange} required={true} autoComplete={'off'}/>
                        <label htmlFor="input" className="Input-label">Last name</label>
                    </div>
                    <div className="Input">
                        <input type="email" id="email" className="Input-text"
                               placeholder="Your E-mail address" value={this.state.email}
                               onChange={this.handleOnChange} required={true} autoComplete={'off'}/>
                        <label htmlFor="input" className="Input-label">E-mail address</label>
                    </div>
                    <div className="Input Textarea">
                        <textarea rows={10} id="massage" className="Input-text"
                               placeholder="Your massage..." value={this.state.massage}
                                  onChange={this.handleOnChange} required={true} autoComplete={'off'}/>
                        <label htmlFor="input" className="Input-label">Your massage</label>
                    </div>

                    <input type={'submit'} value={'Submit'}/>
                </form>

            </div>
        );
    }
}

export default Contact;