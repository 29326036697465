import React, {Component} from 'react';


import {Progress} from "antd";
import '../css/homepage.css';

import TestComponent from "./TestComponent"

class Test extends Component {

    state = {
        loading: false,
        loadedElement: 0,
    };

    waitToLoad = {
        homeimg: require('../../img/home-image.jpg')
    };

    imageLoaded = async () => {
        console.log('one loaded');
        //let img1 = document.getElementById('test');
        //console.log(img1.clientHeight);
        this.setState({loadedElement: await this.state.loadedElement + 1});

        console.log(await this.state.loadedElement, Object.keys(this.waitToLoad).length);

        if (await this.state.loadedElement === Object.keys(this.waitToLoad).length) {

            setTimeout(()=>{
                this.setState({loading:false});

                let img1 = document.getElementById('test');
                console.log('all loaded');
                console.log(img1.clientHeight);

            },0);

        }

    };

    componentDidMount() {
        //console.log('mount');
        //let img1 = document.getElementById('test');
        //console.log(img1.clientHeight)


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log('did update');

    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        //econsole.log('will update')

    }

    render() {
        return (
            <div>
                {this.state.loading ?
                    <div
                        style={{height:'100vh',
                            width:'100vw',
                            backgroundColor:'#4f4f4f',
                            textAlign:'center',
                            verticalAlign:'middle'
                    }}>
                        Loading
                    </div>
                    :
                    null

                }
                        <div id={'test'} >



                            {/*<img src={this.waitToLoad.homeimg} alt="asd" onLoad={this.imageLoaded}/>*/}

                            <TestComponent img={this.waitToLoad} function={this.imageLoaded}/>

                        </div>

                </div>
        );
    }
}

export default Test;