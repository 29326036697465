import React, {Component} from 'react';
import {Progress} from "antd";
import ReactSVG from "react-svg";

class SkillIndicator extends Component {

    state = {
        skillCircleWidth: 65,
        skillCircleImageSize: 25,
    };

    w;
    h;
    m;


    componentWillMount() {
        if (this.props.imageSize) {
            this.w = this.props.imageSize;
            this.h = this.props.imageSize;
            this.m = (this.state.skillCircleWidth - this.props.imageSize) / 2;
        } else {
            this.w = this.state.skillCircleImageSize;
            this.h = this.state.skillCircleImageSize;
            this.m = (this.state.skillCircleWidth - this.state.skillCircleImageSize) / 2;
        }
    }


    render() {

        return (
            <div className={'skill-indicator'}>


                <Progress type="circle" percent={this.props.percent} showInfo={false}
                          strokeWidth={10}
                          //strokeColor={'#212529'}
                          strokeColor={'#F46036'}
                          trailColor={'rgba(255,255,255,0)'}
                          width={this.state.skillCircleWidth}
                />

                <div className="indicator-bg">
                    <img src={this.props.img} alt="skills"
                         onLoad={this.props.function}
                         style={{
                             width: this.w,
                             height: this.h,
                             margin: this.m,
                             ...this.props.imgStyle
                         }}
                    />
                </div>
                {/*<ReactSVG src={this.props.img} alt="skills" className="indicator-bg"
                          beforeInjection={svg => {
                              svg.setAttribute('style', (
                                  'width:' + this.w + 'px ;' +
                                  'height:' + this.h + 'px ;' +
                                  'margin:' + this.m + 'px ;'
                              ))
                          }}
                          afterInjection={(error) => {
                              if (error) {
                                  console.error(error);
                                  return
                              }
                              console.log('svg');
                              this.props.function();
                          }}
                />*/}
            </div>
        );
    }
}

export default SkillIndicator;