import React, {Component} from 'react';

class About extends Component {
    render() {
        return (
            <div>
                I currently studing at Vienna University of Technology. From my early years my biggest passion was
                programming. This is not just a job to do, it is hobby for me. It is great to see how a project
                forming under development, discovering the mistakes the things I would made otherway next time,
                keeps me motivated to be a better myself, be a better programmer.

            </div>
        );
    }
}

export default About;