import React, {Component} from 'react';

class TestComponent extends Component {
    render() {
        return (
            <div style={{
                height:300,
                backgroundColor:'red'
            }}>
                <img src={this.props.img.homeimg} alt="asd" onLoad={this.props.function}/>
            </div>
        );
    }
}

export default TestComponent;