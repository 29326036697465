import React, {Component} from 'react';

import logo from '../../img/logo2.svg';



class PopupMessageRender extends Component{
    render() {
        //console.log(this.props);
        switch (this.props.message) {
            case 'SEND_SUCCESS':
                //console.log('succ');
                return(
                    <div>
                        <p><b>Thank you</b> for contacting me!</p>
                        <p>I will answer your letter as soon as I can.</p>
                        <img className={'popup-sign'} src={logo} alt="logo" style={{filter: 'invert(1)'}}/>
                    </div>
                );
            case 'SEND_ERROR':
                return(
                    <div>
                        <p><b>Oops..</b></p>
                        <p>Something went wrong...</p>
                        <p>Please try it later!</p>
                        <img className={'popup-sign'} src={logo} alt="logo" style={{filter: 'invert(1)'}}/>
                    </div>
                );
            default:
                //console.log('def');
                return(
                    <div>
                        <p><b>Oops..</b></p>
                        <p>Something went wrong...</p>
                        <p>Please try it later!</p>
                        <img className={'popup-sign'} src={logo} alt="logo" style={{filter: 'invert(1)'}}/>
                    </div>
                )
        }
    }
}



class PopupRenderer extends Component {



    closePopUp = (e) => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            //console.log('click');
            //console.log(this.props);
            this.props.functionClose();
        }
    };



    render() {
        //console.log(this.props);
        return (
            <div className="popup-bg" onClick={this.closePopUp}>
                <div className={'popup'}>
                    <PopupMessageRender message={this.props.message}/>
                </div>
            </div>
        );
    }
}

export default PopupRenderer;