import React, {Component} from 'react';
import ReactSVG from 'react-svg';


import Navbar from "../navbar/Navbar";
import ImageContainer from "../homepage-components/image-container"
import Description from "../homepage-components/description";
import About from "../homepage-components/about";
import Skills from "../homepage-components/skills";
import Contact from  "../homepage-components/contact";

class Page3 extends Component {

        ContainersIDs = [
                'h-description',
                'h-about',
                'h-skills',
                'h-contact',
        ];
        ContainerColors = [
            '#2347AB',
            '#3461D4',
            '#ff4211',
            '#28282F',
        ];
        waitToLoad = {
                home:{
                        //orangePlant: require('../../img/orange-planet1.jpg'),
                        //orangePlantWhite: require('../../img/07.17/bricks-brickwall-brickwork-1092364.jpg'),

                        //description: require('../../icons/svg/title/about.svg'),
                        //me: require('../../icons/svg/title/me.svg'),
                        //skills: require('../../icons/svg/title/skills.svg'),
                        //contact: require('../../icons/svg/title/contact.svg'),
                },
                description:{

                },
                about:{

                },
                skills:{
                        photoshop: require('../../icons/svg/skills/adobe-photoshop.svg'),
                        illustrator: require('../../icons/svg/skills/adobe-illustrator.svg'),
                        js: require('../../icons/svg/skills/javascript.svg'),
                        html: require('../../icons/svg/skills/html-5-logo.svg'),
                        //python: require('../../icons/svg/skills/python.svg'),
                },
                contact:{},
        };
        state = {
                containerPaddingTop: 80,
                containerMarginTop: -60,
                heights: [],
                loading: true,
                loadedElement: 0,
                waitToLoadLength: 0,
        };

        setImageHeight = async (heightSum) => {
          console.log(await this.state);

          console.log(heightSum , (60*5));
          let navHeight = document.getElementById('navbar').clientHeight;
          console.log(navHeight, 36);
          let imageContHeight = document.getElementById('h-image-container').clientHeight;
          console.log(imageContHeight, 10);

          let containersHeight = ((heightSum - (await this.state.containerPaddingTop * 5))
              + (navHeight - 36) + (imageContHeight - 10));

          console.log('components height', containersHeight);
          console.log(window.innerHeight - containersHeight);

          /*document.getElementById('h-image-container-2').style.height =
              window.innerHeight - containersHeight + 'px';*/

        };
        setHeights = async() => {

                let heightSum = 0;

                for(let i=0; i < this.ContainersIDs.length; i++){
                        //console.log(this.ContainersIDs[i]);
                        let element = await document.getElementById(this.ContainersIDs[i]);
                        //console.log(await element.getElementsByClassName('container-content')[0]);

                        element.getElementsByClassName('container-title')[0].style.display = 'block';
                        // TODO if you want hide the title

                        element.getElementsByClassName('container-content')[0].style.display = 'block';
                        let elementExpandedHeight = await element.clientHeight
                            - this.state.containerMarginTop - this.state.containerPaddingTop;
                        element.getElementsByClassName('container-content')[0].style.display = 'none';
                        element.getElementsByClassName('container-content')[0].style.opacity = '0';


                        element.getElementsByClassName('container-title')[0].style.display = 'block';
                        //element.getElementsByClassName('container-title')[0].style.display = 'none';
                        element.getElementsByClassName('container-title')[0].style.opacity = '1';
                        let height = element.clientHeight  - this.state.containerMarginTop -
                            this.state.containerPaddingTop;
                        //console.log(elementExpandedHeight);
                        await this.setState(prevState =>
                            ({heights:{...prevState.heights,
                                            [this.ContainersIDs[i]]:
                                                {default: height,
                                                        expanded: elementExpandedHeight}}}));
                        await this.setState({[this.ContainersIDs[i]]: false});
                        element.style.height = element.clientHeight - await this.state.containerPaddingTop + 'px';

                        heightSum += height;
                }

                this.setImageHeight(heightSum);


                //console.log('state', await this.state)
        };
        imageLoaded = async () => {
                if (await this.state.loading) {
                        //await console.log('before',await this.state);
                        //await console.log('loading',await this.state.loading);
                        if (await this.state.loadedElement === await this.state.waitToLoadLength) {
                                setTimeout(async () => {
                                        if (await this.state.loading) {
                                                await this.setState({loading: false});
                                                await console.log('all loaded');
                                                //await console.log('after',await this.state);
                                                await this.setHeights();
                                        }
                                }, 0);
                        } else if (await this.state.loadedElement < await this.state.waitToLoadLength) {
                                console.log('one loaded');
                                this.setState({loadedElement: await this.state.loadedElement + 1});
                        }
                        console.log('loadaed' + await this.state.loadedElement, 'waittoload' + await this.state.waitToLoadLength)
                }
        };
        expandCollapse = async (id) => {
                let element = await document.getElementById(this.ContainersIDs[id]);
                if (await this.state[this.ContainersIDs[id]]) {
                        console.log('shown');
                        element.style.backgroundColor = '#ffffff';

                        element.getElementsByClassName('container-content')[0].style.opacity = 0;
                        element.style.height = await this.state.heights[this.ContainersIDs[id]].default
                            - await this.state.containerPaddingTop + 'px';

                        element.getElementsByClassName('container-title')[0].style.color = '';
                        element.getElementsByClassName('container-title')[0]
                            .getElementsByTagName('path')[0].style.fill = '#212529';
                        //TODO if you want to show title icon

                        setTimeout(async () => {

                                element.getElementsByClassName('container-title')[0].style.display = 'block';
                                element.getElementsByClassName('container-title')[0].style.opacity = '1';
                                element.getElementsByClassName('container-content')[0].style.display = 'none';
                                await this.setState({[this.ContainersIDs[id]]: false});

                                /*if (!await this.state['h-skills']){
                                        console.log('h-skills bg change');

                                        document.getElementById('h-image-container-2')
                                            .getElementsByTagName('img')[0]
                                            .style.opacity = '0';
                                }*/

                        }, 300);
                } else {
                        console.log('settin to',
                            await this.state.heights[this.ContainersIDs[id]].expanded
                            - this.state.containerPaddingTop);
                        element.getElementsByClassName('container-title')[0]
                            .style.transition = 'opacity 0.3s ease-in-out';



                        //element.getElementsByClassName('container-title')[0].style.opacity = '0';
                        element.getElementsByClassName('container-title')[0].style.color = '#ffffff';
                        element.getElementsByClassName('container-title')[0]
                            .getElementsByTagName('path')[0].style.fill = '#ffffff';
                        // TODO if you want to hide the title

                        element.style.transition = 'height 0.5s ease-in-out,' +
                            'background-color 0.5s ease-in-out';
                        element.style.height = await this.state.heights[this.ContainersIDs[id]].expanded -
                            this.state.containerPaddingTop + 'px';
                        element.getElementsByClassName('container-content')[0].style.display = 'block';
                        element.getElementsByClassName('container-content')[0].style.transition =
                            'opacity 0.55s ease-in-out';

                        setTimeout(async () => {
                                element.style.backgroundColor = this.ContainerColors[id];
                                //element.getElementsByClassName('container-title')[0].style.display = 'none';
                                // TODO if you want to hide the title
                                element.getElementsByClassName('container-content')[0].style.opacity = 1;
                                await this.setState({[this.ContainersIDs[id]]: true});

                               /* if (await this.state['h-skills']){
                                        console.log('h-skills bg change');

                                        document.getElementById('h-image-container-2')
                                            .getElementsByTagName('img')[0]
                                            .style.opacity = '1';
                                }*/

                        }, 0);
                }

        };

        async componentDidMount() {

                //this.imageLoaded()


                //console.log(await this.state)
                console.log('length of img dict',Object.keys(this.waitToLoad).length);
                let waitToLoadLength = 0;
                let i = 0;
                while (i < Object.keys(this.waitToLoad).length) {
                        waitToLoadLength += Object.keys(this.waitToLoad[Object.keys(this.waitToLoad)[i]]).length;
                        i++
                }
                console.log('waiting for', waitToLoadLength);
                this.setState({waitToLoadLength:waitToLoadLength});

                this.imageLoaded();


        }


        render() {
                return (
            <div style={{
                    backgroundColor:'#3e3e3e',
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
            }}>

                    <Navbar/>

                    <div id={'h-image-container'} className={'home-container'}>
                            <ImageContainer/>
                            <div className={'home-image'}>
                                    <img src={require('../../img/alexandru-acea--WBYxmW4yuw-unsplash.jpg')}
                                         alt="asd"/>
                            </div>
                    </div>




                    <div id={this.ContainersIDs[0]} className={'home-container'}
                         onClick={event => this.expandCollapse(0)}
                    >
                            <div className={'container-title'}>
                                    {/*<img src={this.waitToLoad.home.description} alt="about"*/}
                                    {/*     className={'container-icon'} onLoad={this.imageLoaded}/>*/}

                                    <ReactSVG src={require('../../icons/svg/title/about.svg')}
                                              className={'container-icon'}/>
                                    What I do
                            </div>
                            <div className={'container-content'} >
                                <Description/>
                            </div>
                    </div>

                    <div id={this.ContainersIDs[1]} className="home-container"
                         onClick={event => this.expandCollapse(1)}>
                            <div className={'container-title'}>
                                    {/*<img src={this.waitToLoad.home.me} alt="about"*/}
                                    {/*     className={'container-icon'} onLoad={this.imageLoaded}/>*/}
                                    <ReactSVG src={require('../../icons/svg/title/me.svg')}
                                              className={'container-icon'}/>
                                    About Me
                            </div>
                            <div className={'container-content'} >
                                    <About/>
                            </div>
                    </div>


                   {/* <div id={'h-image-container-2'} className={'home-container'}>
                            <div id={'img-cont-content'}>
                                    <h1>UX/UI</h1>
                                    <h1>DESIGN</h1>
                            </div>

                            <div className={'home-image'}>

                                    <img src={this.waitToLoad.home.orangePlant} onLoad={this.imageLoaded}
                                         alt="asd" style={{position: 'absolute', opacity: '0'}}/>


                                    <img src={this.waitToLoad.home.orangePlantWhite} onLoad={this.imageLoaded}
                                         alt="asd"/>
                            </div>
                    </div>*/}

                    <div id={this.ContainersIDs[2]} className="home-container"
                         onClick={event => this.expandCollapse(2)}>
                            <div className={'container-title'}>
                                    {/*<img src={this.waitToLoad.home.skills} alt="about"*/}
                                    {/*     className={'container-icon'} onLoad={this.imageLoaded}/>*/}

                                         <ReactSVG src={require('../../icons/svg/title/skills.svg')}
                                                   className={'container-icon'}/>
                                         Skills


                                        <div className={'container-title-long'}>
                                                <Skills img={this.waitToLoad.skills}
                                                        function={this.imageLoaded}/>
                                        </div>

                            </div>
                            <div className={'container-content'} >
                                    {/*<Skills img={this.waitToLoad.skills} function={this.imageLoaded}/>*/}
                                    <About/>
                            </div>
                    </div>




                    <div id={this.ContainersIDs[3]} className="home-container"
                         onClick={event => this.expandCollapse(3)}>
                            <div className={'container-title'}>
                                    {/*<img src={this.waitToLoad.home.contact} alt="about"*/}
                                    {/*     className={'container-icon'} onLoad={this.imageLoaded}/>*/}
                                    <ReactSVG src={require('../../icons/svg/title/contact.svg')}
                                              className={'container-icon'}/>

                                    Contact
                            </div>
                            <div className={'container-content'} >
                                    <Contact/>
                            </div>
                    </div>

            </div>
        );
    }
}

export default Page3;