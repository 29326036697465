import React, {Component} from 'react';
import SkillIndicator from "./subcomponents/Skill-Indicator";
import {Progress} from "antd";




class Skills extends Component {



    render() {
            return (
                <div>

                    <link rel="stylesheet"
                          href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                          crossOrigin="anonymous"/>


                        <div className="container">
                            <div className="row">
                                <div className="col-3">
                                    <SkillIndicator percent={90} img={this.props.img.photoshop} function={this.props.function}/>
                                </div>
                                <div className="col-3">
                                    <SkillIndicator percent={65} img={this.props.img.illustrator} function={this.props.function}/>
                                </div>
                                <div className="col-3">
                                    <SkillIndicator percent={65} img={this.props.img.photoshop} function={this.props.function}/>
                                </div>
                                <div className="col-3">
                                    <SkillIndicator percent={90} img={this.props.img.photoshop}
                                                    function={this.props.function}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <SkillIndicator percent={90} img={this.props.img.photoshop} function={this.props.function}/>
                                </div>
                                <div className="col-3">
                                    <SkillIndicator percent={65} img={this.props.img.illustrator} function={this.props.function}/>
                                </div>
                                <div className="col-3">
                                    <SkillIndicator percent={65} img={this.props.img.photoshop} function={this.props.function}/>
                                </div>
                                <div className="col-3">
                                    <SkillIndicator percent={90} img={this.props.img.photoshop}
                                                    function={this.props.function}/>
                                </div>
                            </div>

                        </div>




                    {/*<div id={'skill-indicator'}>


                        <Progress type="circle" percent={90} showInfo={false}
                                  strokeWidth={10}
                                  strokeColor={'#000'}
                                  trailColor={'transparent'}
                                  width={100}
                        />

                        <img src={this.props.img.img1} alt="skills" onLoad={this.props.function}/>

                    </div>*/}

                </div>
            );
    }
}

export default Skills;